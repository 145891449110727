import { HTMLAttributes } from 'react';

function IconYoutube(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z'
        fill='#FF0000'
      />
      <path
        d='M36.265 18.0734C35.9706 16.9424 35.1031 16.0518 34.0016 15.7495C32.0053 15.2002 24 15.2002 24 15.2002C24 15.2002 15.9946 15.2002 13.9982 15.7495C12.8967 16.0518 12.0292 16.9424 11.7348 18.0734C11.2 20.1232 11.2 24.4002 11.2 24.4002C11.2 24.4002 11.2 28.677 11.7348 30.727C12.0292 31.858 12.8967 32.7486 13.9982 33.051C15.9946 33.6002 24 33.6002 24 33.6002C24 33.6002 32.0053 33.6002 34.0016 33.051C35.1031 32.7486 35.9706 31.858 36.265 30.727C36.8 28.677 36.8 24.4002 36.8 24.4002C36.8 24.4002 36.8 20.1232 36.265 18.0734Z'
        fill='white'
      />
      <path d='M21.6 28.7998V20.7998L28 24.8L21.6 28.7998Z' fill='#FF0000' />
    </svg>
  );
}

export default IconYoutube;
