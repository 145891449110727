import { NavigationItem, PartnerFragment } from '@amf/shared/types/graphql';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';

import Navigation from 'components/navigation/Navigation';
import Footer from 'components/layout/Footer';

type Props = {
  navigation: NavigationItem[];
  partners: PartnerFragment[];
  children: ReactNode;
};

function Layout({ navigation, children, partners }: Props) {
  return (
    <>
      <Navigation navigation={navigation} />
      <motion.div
        initial={{ opacity: 0.8, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.2, ease: 'linear' }}
      >
        {children}
      </motion.div>
      <Footer partners={partners} />
    </>
  );
}

export default Layout;
