import { DOMAttributes, useRef, useState } from 'react';
import classNames from 'classnames';
import { NavigationItemFragment } from '@amf/shared/types/graphql';
import useCurrentPage from '@amf/shared/hooks/useCurrentPage';
import useOnClickOutside from '@amf/shared/hooks/useOnClickOutside';
import { AnimatePresence, motion } from 'framer-motion';

import NavigationMenuItem from 'components/navigation/NavigationItem';
import Container from 'components/layout/Container';
import Triangle from 'assets/Triangle';

interface NavigationMenuDropdownProps extends DOMAttributes<HTMLAnchorElement> {
  item: NavigationItemFragment;
  className?: string;
  selectedClassName?: string;
  childClassName?: string;
  childSelectedClassName?: string;
}

export default function NavigationMenuDropdown({
  item,
  className,
  selectedClassName,
  childClassName,
  childSelectedClassName,
  ...props
}: NavigationMenuDropdownProps) {
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setOpened(false));
  const [opened, setOpened] = useState(false);
  const current = useCurrentPage(item.path, item.children?.map(c => c.path) ?? []);
  if (!item.children || item.children.length === 0) {
    return null;
  }

  return (
    <div ref={ref} className='relative lg:h-full flex flex-col justify-center group min-h-[56px]'>
      <button
        type='button'
        className={classNames(
          'normal-case relative',
          'py-4',
          'outline-0 font-primary font-bold truncate lg:py-1.5 lg:px-2 focus:text-wmf-green hover:text-wmf-green',
          {
            'font-black': current,
          },
        )}
        onClick={() => setOpened(!opened)}
      >
        <span className='flex items-center gap-1.5'>
          <span className='flex-grow lg:flex-grow-0 text-left'>{item.title}</span>
          <Triangle
            className={classNames('w-2 h-auto mr-2', {
              'rotate-180': opened,
            })}
          />
        </span>
        <div className='absolute bottom-0 left-0 w-full h-[2px] bg-wmf-green rounded-full scale-x-0 group-hover:scale-x-1' />
      </button>
      <AnimatePresence>
        {opened && (
          <>
            <Triangle className='text-zinc-100 w-4 h-auto rotate-180 absolute bottom-0 left-1/2 -translate-x-1/2' />
            <motion.div
              transition={{ duration: 0.1 }}
              initial={{ opacity: 0, y: -4 }}
              animate={{ opacity: 1, y: 0 }}
              className={classNames(
                'lg:fixed left-0 top-20 w-full lg:bg-zinc-100 lg:py-3 lg:shadow-lg',
                {},
              )}
            >
              <div className='flex flex-col lg:flex-row lg:items-center justify-center lg:gap-6'>
                {item.children.map(child => (
                  <NavigationMenuItem
                    key={child.id}
                    item={child}
                    className='outline-0 font-primary font-bold truncate py-2 lg:py-1.5 lg:px-2 focus:text-wmf-green hover:text-wmf-green text-[16px] border-transparent min-h-[40px] lg:min-h-0'
                    selectedClassName={childSelectedClassName}
                    {...props}
                  />
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}
