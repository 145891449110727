import { NavigationItem } from '@amf/shared/types/graphql';
import Link from 'next/link';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid';
import { useCallback, useEffect, useState } from 'react';
import { useLockedBody } from 'usehooks-ts';

import Container from 'components/layout/Container';
import NavigationMenuItem from 'components/navigation/NavigationItem';
import IconBrand from 'assets/brand/IconBrand';
import useBreakpoint from 'hooks/useBreakpoint';
import BrandBackgroundGraphic from 'assets/BrandBackgroundGraphic';

type Props = {
  navigation: NavigationItem[];
};

function Navigation({ navigation }: Props) {
  const { active: isDesktop } = useBreakpoint('lg');
  const [, setLocked] = useLockedBody(false);
  const [opened, setOpened] = useState(false);
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 200], [1, 0]);

  const toggleMenu = useCallback(
    (opened: boolean) => {
      if (opened) {
        setOpened(true);
        setLocked(true);
      } else {
        setOpened(false);
        setLocked(false);
      }
    },
    [setLocked],
  );

  useEffect(() => {
    if (isDesktop) toggleMenu(false);
  }, [isDesktop, toggleMenu]);

  return (
    <>
      <div className='h-16 sm:h-20' />
      <div
        className='bg-wmf-navy text-white border-b-2 border-b-[#00022b] shadow-2xl h-16 sm:h-20 fixed top-0 left-0 w-full z-40'
        style={{ zIndex: 1001 }}
      >
        <Container className='flex items-start justify-between'>
          {opened ? (
            <div />
          ) : (
            <Link href='/' passHref>
              <motion.a className='outline-0 w-24 min-w-[6rem] sm:w-36 sm:min-w-[9rem] p-3 sm:p-6 relative z-10 sm:h-[120px]'>
                <BrandBackgroundGraphic className='absolute -top-[100px] sm:-top-[140px] left-1/2 -translate-x-1/2 w-[200px] h-[240px] sm:w-[320px] sm:h-[340px] text-[#00022b] drop-shadow-2xl' />
                <IconBrand opacity={opacity} className='text-white relative z-10' />
              </motion.a>
            </Link>
          )}
          <div className='h-16 sm:h-20 flex lg:hidden items-center justify-center'>
            <button
              type='button'
              className='w-12 h-12 flex items-center justify-center -mr-3'
              onClick={() => setOpened(!opened)}
            >
              {opened ? (
                <XMarkIcon className='w-6 sm:w-8 h-6 sm:h-8' />
              ) : (
                <Bars3Icon className='w-6 sm:w-8 h-6 sm:h-8' />
              )}
            </button>
          </div>
          <div className='hidden lg:flex items-center justify-end flex-grow h-16 sm:h-20 px-3 gap-1 text-lg leading-none'>
            {navigation.map(item => (
              <NavigationMenuItem
                key={item.id}
                item={item}
                className='outline-0 font-primary font-bold truncate py-1.5 hover:bg-wmf-sky/10 h-full flex items-center px-4'
                unselectedClassName=''
                selectedClassName='bg-blue-800 hover:bg-blue-800'
              />
            ))}
          </div>
          {/*<div className='hidden lg:flex items-center h-16 sm:h-20'>
            <Button variant='red' size='sm' icon={TicketIcon}>
              Order ticket
            </Button>
          </div>*/}
        </Container>
      </div>
      {opened && (
        <div className='flex flex-col lg:hidden fixed top-16 sm:top-20 w-full bottom-0 left-0 z-30 bg-wmf-navy text-white'>
          <Container className='h-full flex flex-col px-0'>
            <div className='flex flex-col flex-grow divide-y divide-white/20 border-b border-white/20 overflow-y-auto'>
              {navigation.map(item => (
                <NavigationMenuItem
                  key={item.id}
                  item={item}
                  className='outline-0 font-primary font-bold truncate min-h-[56px] py-4 focus:text-wmf-green hover:text-wmf-green'
                  selectedClassName='text-wmf-green'
                  onClick={() => toggleMenu(false)}
                />
              ))}
            </div>
            {/*<div className='py-6 bg-white'>
              <Button variant='red' size='sm' icon={TicketIcon} className='w-full'>
                Order ticket
              </Button>
            </div>*/}
          </Container>
        </div>
      )}
    </>
  );
}

export default Navigation;
