import { useCallback, useEffect, useState } from 'react';

const BREAKPOINTS = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

type Breakpoint = keyof typeof BREAKPOINTS;

function useBreakpoint(breakpoint: Breakpoint) {
  const [active, setActive] = useState(false);

  const resolve = useCallback(() => {
    setActive(window.innerWidth >= BREAKPOINTS[breakpoint]);
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener('resize', resolve);
    resolve();
    return () => window.removeEventListener('resize', resolve);
  }, [resolve]);

  return { active };
}

export default useBreakpoint;
