/* eslint-disable @next/next/no-img-element */
import { PartnerFragment } from '@amf/shared/types/graphql';

import Container from 'components/layout/Container';
import IconFacebook from 'assets/social/IconFacebook';
import IconTwitter from 'assets/social/IconTwitter';
import IconInstagram from 'assets/social/IconInstagram';
import IconYoutube from 'assets/social/IconYoutube';

type Props = {
  partners: PartnerFragment[];
};

function Footer({ partners }: Props) {
  return (
    <>
      <div className='bg-neutral-100 text-white py-12 sm:py-24 border-t border-neutral-200'>
        <Container>
          {partners.length > 0 && (
            <>
              <h2 className='text-3xl md:text-5xl font-black font-primary text-neutral-950 m-0'>
                Partners
              </h2>
              <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 my-3 sm:my-6'>
                {partners.map(partner => (
                  <a
                    key={partner.id}
                    href={partner.link}
                    title={partner.title}
                    target='_blank'
                    rel='noreferrer'
                    className='bg-white p-8 flex items-center justify-center h-[120px] xl:h-[200px] rounded shadow hover:shadow-lg transition-all'
                  >
                    <img
                      src={partner.imageUrl}
                      alt={partner.title}
                      className='max-w-[250px] max-h-[100px] w-full h-full object-contain'
                    />
                  </a>
                ))}
              </div>
            </>
          )}
          <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 sm:gap-4 mt-16'>
            <div className='text-neutral-950'>
              <h3 className='text-xl md:text-3xl font-bold normal-case font-primary text-neutral-950 m-0 mb-2'>
                Address
              </h3>
              <address className='not-italic'>
                World Minifootball Federation
                <br />
                Switzerland:
                <br />
                Strada dell’Argine, 2<br />
                6512 Giubiasco
              </address>
            </div>
            <div className='text-neutral-950'>
              <h3 className='text-xl md:text-3xl font-bold normal-case font-primary text-neutral-950 m-0 mb-2'>
                Contact
              </h3>
              <p>
                <a href='mailto:info@minifootball.com' className='text-wmf-green font-bold'>
                  info@minifootball.com
                </a>
              </p>
            </div>
            <div className='text-neutral-950 sm:mt-10 md:mt-0'>
              <h3 className='text-xl md:text-3xl font-bold normal-case font-primary text-neutral-950 m-0 mb-2'>
                Social
              </h3>
              <div className='flex items-center gap-3'>
                <a
                  href='https://www.facebook.com/minifootballcom/'
                  target='_blank'
                  className='p-2 bg-black/5 rounded-full hover:scale-[1.04] transition-all hover:bg-black/10'
                  rel='noreferrer'
                >
                  <IconFacebook className='w-10 h-10' />
                </a>
                <a
                  href='https://twitter.com/minifootballcom'
                  target='_blank'
                  className='p-2 bg-black/5 rounded-full hover:scale-[1.04] transition-all hover:bg-black/10'
                  rel='noreferrer'
                >
                  <IconTwitter className='w-10 h-10' />
                </a>
                <a
                  href='https://www.instagram.com/minifootballcom/'
                  target='_blank'
                  className='p-2 bg-black/5 rounded-full hover:scale-[1.04] transition-all hover:bg-black/10'
                  rel='noreferrer'
                >
                  <IconInstagram className='w-10 h-10' />
                </a>
                <a
                  href='https://www.youtube.com/@minifootballcom'
                  target='_blank'
                  className='p-2 bg-black/5 rounded-full hover:scale-[1.04] transition-all hover:bg-black/10'
                  rel='noreferrer'
                >
                  <IconYoutube className='w-10 h-10' />
                </a>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <div className='bg-neutral-200 py-4 border-t border-neutral-300'>
        <Container className='text-center text-xs text-neutral-500 font-bold'>
          <p>&copy; {new Date().getFullYear()} WMF</p>
        </Container>
      </div>
    </>
  );
}

export default Footer;
