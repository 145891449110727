import { HTMLAttributes } from 'react';

function Triangle(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M0 0L5 5L10 0H0Z' fill='currentColor' />
    </svg>
  );
}

export default Triangle;
