import { HTMLAttributes } from 'react';

function BrandBackgroundGraphic(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg
      viewBox='0 0 100 100'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      preserveAspectRatio='none'
    >
      <path
        fill='currentColor'
        d='M17.6,-27.6C24.1,-26.9,31.3,-24.6,34.4,-19.7C37.5,-14.8,36.4,-7.4,34.1,-1.3C31.8,4.7,28.2,9.5,25.7,15.4C23.2,21.2,21.8,28.2,17.6,31.6C13.5,35,6.8,34.8,-0.2,35.1C-7.2,35.5,-14.4,36.5,-18.9,33.3C-23.4,30.2,-25.3,22.9,-26.2,16.7C-27.1,10.4,-27.2,5.2,-26.3,0.5C-25.4,-4.2,-23.6,-8.4,-22.1,-13.7C-20.7,-19.1,-19.6,-25.6,-16,-28C-12.4,-30.4,-6.2,-28.6,-0.3,-28.1C5.6,-27.6,11.2,-28.4,17.6,-27.6Z'
        width='100%'
        height='100%'
        transform='translate(50 50)'
        style={{ transform: 'all 0.3x ease 0x' }}
      />
    </svg>
  );
}

export default BrandBackgroundGraphic;
