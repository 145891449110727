import { HTMLAttributes } from 'react';

function IconTwitter(props: HTMLAttributes<SVGSVGElement>) {
  return (
    <svg viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z'
        fill='#55ACEE'
      />
      <path
        d='M23.2812 19.5074L23.3316 20.3379L22.4922 20.2362C19.4369 19.8464 16.7677 18.5244 14.5013 16.3042L13.3934 15.2026L13.108 16.0161C12.5036 17.8295 12.8897 19.7447 14.1488 21.0327C14.8203 21.7445 14.6692 21.8462 13.5109 21.4225C13.108 21.2869 12.7554 21.1853 12.7219 21.2361C12.6044 21.3547 13.0073 22.897 13.3262 23.5071C13.7627 24.3545 14.6524 25.185 15.6261 25.6765L16.4487 26.0663L15.475 26.0832C14.5349 26.0832 14.5013 26.1002 14.6021 26.4561C14.9378 27.5577 16.264 28.7271 17.7413 29.2356L18.7822 29.5915L17.8756 30.1338C16.5326 30.9134 14.9546 31.3541 13.3766 31.388C12.6211 31.4049 12 31.4727 12 31.5236C12 31.693 14.0481 32.6421 15.24 33.015C18.8157 34.1166 23.063 33.6421 26.2526 31.7608C28.5189 30.4219 30.7852 27.7611 31.8428 25.185C32.4136 23.8122 32.9844 21.3039 32.9844 20.1006C32.9844 19.321 33.0347 19.2193 33.9748 18.2871C34.5288 17.7448 35.0492 17.1516 35.15 16.9821C35.3178 16.6601 35.3011 16.6601 34.4449 16.9482C33.018 17.4567 32.8165 17.3889 33.5216 16.6262C34.042 16.0839 34.6631 15.1009 34.6631 14.8128C34.6631 14.762 34.4113 14.8467 34.1259 14.9992C33.8238 15.1687 33.1523 15.4229 32.6486 15.5755L31.7421 15.8636L30.9195 15.3043C30.4663 14.9992 29.8283 14.6603 29.4926 14.5586C28.6364 14.3213 27.327 14.3552 26.5548 14.6264C24.4563 15.389 23.1301 17.355 23.2812 19.5074Z'
        fill='white'
      />
    </svg>
  );
}

export default IconTwitter;
