import { HTMLAttributes } from 'react';
import classNames from 'classnames';

function Container({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('container mx-auto px-6 md:px-8 lg:px-10 xl:px-12', className)}
      {...props}
    />
  );
}

export default Container;
